import { useSettingsStore } from '~/stores/useSettingsStore';
import { storeToRefs } from 'pinia';
export default defineNuxtRouteMiddleware(async (to, from) => {
  const { siteParts } = await storeToRefs(useSettingsStore());
  const { $fullpath } = useSiteSettings();
  const router = useRouter();

  const cookie = useCookie('media-corner');
  if (cookie.value) {
    return;
  }

  const url = $fullpath(siteParts.value?.general?.loginPage?._ref || '');

  router.push(url);
});
